import {
  Flex,
  HStack,
  StackDivider,
  useColorModeValue,
} from '@chakra-ui/react';
import type { NextPage } from 'next';
import SignInContainer from 'components/SignInContainer';
import Logo from 'components/Logo';
import useHandleToggleTheme from 'utils/whiteLabel/hooks';

const Login: NextPage = () => {
  const dividerColor = useColorModeValue('black', 'white');
  const bg = useColorModeValue('white', 'gray.900');
  useHandleToggleTheme();

  return (
    <>
      <Flex w="100vw" h="100vh" bgColor={bg} align="center" justify="center">
        <HStack
          spacing="24"
          position="absolute"
          divider={<StackDivider bgColor={dividerColor} />}>
          <Logo size="lg" />
          <Flex direction="column">
            <SignInContainer />
          </Flex>
        </HStack>
      </Flex>
    </>
  );
};

export default Login;

import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/dist/client/router';
import { useEffect } from 'react';
import useUser from '~/components/SignInContainer/queries/user/useUser';
import * as Sentry from '@sentry/nextjs';
import useUserIsAdvisorCustomerService from './hooks/useUserIsAdvisorCustomerService';
import SignIn from './SignIn';
import SignInAdvisorOverrideSelector from './SignInAdvisorOverrideSelector';

export type SignInFormData = {
  username: string;
  password: string;
};

const SignInContainer = () => {
  const router = useRouter();
  const { isLoading, isAuthenticated } = useAuth0();
  const isAdvisorUserCostumerService = useUserIsAdvisorCustomerService();
  const { isSuccess, isLoading: isLoadingUser, data } = useUser();

  Sentry.setUser({ email: data?.email });

  useEffect(() => {
    if (!isSuccess) return;

    if (!isLoading && isAuthenticated && !isAdvisorUserCostumerService)
      router.push('/qualified-purchasers/dashboard');

    if (!isLoading && !isAuthenticated && window !== undefined)
      window.localStorage.clear();
  }, [isLoading, isAuthenticated, isSuccess, isAdvisorUserCostumerService]);

  const renderContainerComponent = () => {
    if (!isAuthenticated) return <SignIn />;

    if (isLoadingUser)
      return <CircularProgress isIndeterminate color="brand.perwinkle.500" />;

    if (isAuthenticated && isAdvisorUserCostumerService)
      return <SignInAdvisorOverrideSelector />;

    return null;
  };

  return (
    <Flex
      h="md"
      w="md"
      py="4"
      px="16"
      justify="center"
      align="center"
      direction="column">
      {renderContainerComponent()}
    </Flex>
  );
};

export default SignInContainer;

import {
  CircularProgress,
  Text,
  VStack,
  Link as ChakraLink,
} from '@chakra-ui/react';
import ArrowIcon from 'components/Icons/ArrowIcon';
import { useAuth0 } from '@auth0/auth0-react';
import NextLink from 'next/link';
import StyledButton from '../../StyledButton';

const SignIn = () => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <VStack>
      <Text fontSize="4xl" textAlign="center" w="sm" mb="10">
        Welcome back, please login to your account.
      </Text>
      {isLoading || isAuthenticated ? (
        <CircularProgress isIndeterminate color="brand.perwinkle.500" />
      ) : (
        <StyledButton
          onClick={handleLogin}
          variant="outline"
          textTransform="uppercase"
          rightIcon={<ArrowIcon direction="right" />}>
          Sign In
        </StyledButton>
      )}
      <Text fontSize="xs" w="max-content">
        By signing up you are agreeing to
        <ChakraLink
          as={NextLink}
          href="https://www.glasfunds.com/privacy-policy/"
          mt="10"
          fontSize="xs"
          fontWeight="semibold"
          textTransform="uppercase"
          display="inline-flex"
          ml="1">
          Terms and Conditions
          <ArrowIcon
            direction="right"
            ml={1.5}
            mt={0.5}
            size={3}
            display="flex"
          />
        </ChakraLink>
      </Text>
    </VStack>
  );
};

export default SignIn;
